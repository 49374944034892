import React, { useEffect, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import ScanView from "./view";
import { PageContext } from "../../lib/context";
import { useMutation } from "@apollo/react-hooks";
import { APPLY_PASS, SCAN_QR } from "../../lib/mutations";
import moment from "moment";
import S3 from "react-aws-s3";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIR_NAME /* optional */,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
  s3Url: process.env.REACT_APP_S3_URL,
};

const ScanController = (props) => {
  const [showScanner, setShowScanner] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const S3Client = new S3(config);
  
  const [form] = Form.useForm();

  const [scanQR, { loading: scanning }] = useMutation(SCAN_QR);
  const [applyPass, { loading: applying }] = useMutation(APPLY_PASS);

  const handleScan = (id) => {
    if (id) {
      setVehicleData(null);
      setShowScanner(false);
      async function scan() {
        let res = await scanQR({
          variables: {
            id,
          },
        });
        if (res?.data?.scan_qr?.code === 200) {
          setVehicleData(res?.data?.scan_qr?.data);
        }
      }
      scan(id);
    }
  };

  const handleError = (err) => {
    console.error(err);
    alert(err);
  };

  const handlePassApplication = async (data) => {
    setLoading(true)
    if (images.length < 2) {
      notification.error({
        message: "Must upload all required photos",
      });
      setLoading(false);
      return;
    }
    const vehicle_data = JSON.parse(vehicleData);
    const passes = vehicle_data?.pass_applications;
    const approved_passes = passes.filter((i) => i.status === "approved");
    let isValid = true;
    let new_date_from = moment(data.date_range[0]).format("MMM-DD-YYYY"); // new date from
    let new_date_to = moment(data.date_range[1]).format("MMM-DD-YYYY"); // new date to
    approved_passes.map((pass) => {
      let pass_date_from = moment(pass.date_from).format("MMM-DD-YYYY"); // approved date from
      let pass_date_to = moment(pass.date_to).format("MMM-DD-YYYY"); // approved date to
      if (
        moment(new_date_from).isBetween(pass_date_from, pass_date_to) || // check if new from is in between approved dates
        moment(new_date_to).isBetween(pass_date_from, pass_date_to) || // check if new to is in between approved dates
        moment(pass_date_from).isBetween(new_date_from, new_date_to) || // check if new dates consumes approved from
        moment(pass_date_to).isBetween(new_date_from, new_date_to) || // check if new dates consumes approved to
        moment(new_date_from).isSame(pass_date_to) || // check if new from is equal to approved to
        moment(new_date_to).isSame(pass_date_from) // check if new to is equal to approved from
      ) {
        isValid = false;
      }
    });
    if (!isValid) {
      notification.error(
        "You already have an APPROVED pass with this date range."
      );
      return;
    } else {
      const image_locations = []
      for (var i=0; i< images.length; i++) {
        let item = images[i]
        const { location: user_picture } = await S3Client.uploadFile(
          item.file,
          "pic-" + moment().format("YYYY-MM-DD h:m:s").toString()
        );
        image_locations.push({
          key: item.key,
          user_picture
        })
      }
      let res = await applyPass({
        variables: {
          id: vehicle_data?.vehicle_info?.id,
          date_from: new_date_from,
          date_to: new_date_to,
          notice_of_compliance: image_locations.filter((i)=>i.key === 'notice_of_compliance')?.[0]?.user_picture,
          certificate_of_downtime: image_locations.filter((i)=>i.key === 'certificate_of_downtime')?.[0]?.user_picture
        },
      });
      if (res?.data?.apply_pass?.code === 200) {
        notification.success({
          message: "Successfully submitted application",
        });
        form.resetFields();
        setVisible(false);
        setVehicleData(null);
        setTimeout(() => {
          window.location.reload();
        }, 400);
      } else {
        notification.error({
          message: "Failed to submit application pass",
        });
      }
    }
  };

  const handleImageUpload = (key, file) => {
    setImages([...images, {key,file}])
  }

  const pageValues = {
    handleScan,
    handleError,
    showScanner,
    setShowScanner,
    vehicleData,
    scanning,
    setVehicleData,
    setVisible,
    visible,
    handlePassApplication,
    form,
    handleImageUpload,
    loading
  };

  return (
    <PageContext.Provider value={pageValues}>
      <ScanView />
    </PageContext.Provider>
  );
};

export default ScanController;
