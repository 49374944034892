import { Button } from "antd";
import React from "react";

const LandingView = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center ">
      <div className="flex flex-col lg:flex-row justify-evenly items-center">
        <img
          src={require("../../assets/app-logo.png")}
          style={{ height: 220, width: 300, objectFit: "contain" }}
        />
        <img
          src={require("../../assets/cebu-province-logo.jpg")}
          style={{ height: 150, width: 150 }}
        />
      </div>
      <div>
        <p className="text-2xl font-bold">LIVESTOCK TRANSPORT PASS</p>
        <p className="text-base font-bold">version 1.2.0</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between mt-10">
        <Button
          type="primary"
          className="w-48 h-12 bg-green-500 border-green-500 rounded-full mr-2 font-bold mt-5"
          onClick={()=>window.location.href="/registration"}
        >
          Register Vehicle
        </Button>
        <Button
          type="primary"
          className="w-48 h-12 bg-green-500 border-green-500 rounded-full mr-2 font-bold mt-5"
          onClick={()=>window.location.href="/scan"}
        >
          Check Pass Status
        </Button>
      </div>
    </div>
  );
};

export default LandingView;
