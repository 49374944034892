import React, { useEffect, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import RegistrationView from "./view";
import { PageContext } from "../../lib/context";
import {
	INSERT_BUSINESS_CERTIFICATES_IMAGES,
	INSERT_TRUCK_BOYS,
	INSERT_VEHICLES,
	UPSERT_USERS,
	UPSERT_USER_BUSINESS_CERTIFICATES,
	UPSERT_USER_ITEMS,
} from "../../lib/mutations";
import { useMutation } from "@apollo/react-hooks";
import S3 from "react-aws-s3";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { uploadFile } from "../../lib/util";
import { useCallback } from "react";

const config = {
	bucketName: process.env.REACT_APP_BUCKET_NAME,
	dirName: process.env.REACT_APP_DIR_NAME /* optional */,
	region: process.env.REACT_APP_REGION,
	accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
	s3Url: process.env.REACT_APP_S3_URL,
};

const Registration = (props) => {
	const [form] = Form.useForm();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [images, setImages] = useState([]);
	const [tbimages, setTbImages] = useState([]);
	const [registrationId, setRegistrationId] = useState("");
	const [visible, setVisible] = useState(false);
	const [progress, setProgress] = useState(0);

	const S3Client = new S3(config);

	const [addVehicle] = useMutation(INSERT_VEHICLES);
	const [addTruckBoys] = useMutation(INSERT_TRUCK_BOYS);

	const updateProgress = useCallback(
		(value) => {
			setProgress(value);
		},
		[setProgress]
	);

	const handleFinishForm = async (data) => {
		setLoading(true);
		if (
			data?.items?.length > 0 &&
			tbimages.length !== data?.items?.length
		) {
			notification.error({
				message: "Missing Truck boy image",
			});
			setLoading(false);
			return;
		}

		if (images.length < 9) {
			notification.error({
				message: "Must upload all required photos",
			});
			setLoading(false);
			return;
		}

		if (!data?.items?.length) {
			notification.error({
				message: "Must list down all companion truck boys",
			});
			setLoading(false);
			return;
		}
		const totalImages = images.length + tbimages.length;
		const image_locations = [];
		let imageindex = 0;
		for (var i = 0; i < images.length; i++) {
			let item = images[i];
			let { data: user_picture } = await uploadFile(item.file);
			image_locations.push({
				key: item.key,
				user_picture: user_picture.secure_url,
			});
			imageindex++;
			updateProgress((imageindex / totalImages) * 100);
		}
		const tb_locations = [];
		for (var i = 0; i < tbimages.length; i++) {
			let item = tbimages[i];
			let { data: user_picture } = await uploadFile(item.file);
			tb_locations.push({
				key: item?.key,
				user_picture: user_picture.secure_url,
			});
			imageindex++;
			updateProgress((imageindex / totalImages) * 100);
		}

		const payloadVehicle = { ...data };
		delete payloadVehicle.items;
		payloadVehicle["business_permit"] = image_locations.filter(
			(i) => i.key === "business_permit"
		)?.[0]?.user_picture;
		payloadVehicle["truck_picture_front"] = image_locations.filter(
			(i) => i.key === "truck_picture_front"
		)?.[0]?.user_picture;
		payloadVehicle["truck_picture_side"] = image_locations.filter(
			(i) => i.key === "truck_picture_side"
		)?.[0]?.user_picture;
		payloadVehicle["truck_or"] = image_locations.filter(
			(i) => i.key === "truck_or"
		)?.[0]?.user_picture;
		payloadVehicle["truck_cr"] = image_locations.filter(
			(i) => i.key === "truck_cr"
		)?.[0]?.user_picture;
		payloadVehicle["drivers_picture"] = image_locations.filter(
			(i) => i.key === "drivers_picture"
		)?.[0]?.user_picture;
		payloadVehicle["drivers_license"] = image_locations.filter(
			(i) => i.key === "drivers_license"
		)?.[0]?.user_picture;
		payloadVehicle["handlers_permit"] = image_locations.filter(
			(i) => i.key === "handlers_permit"
		)?.[0]?.user_picture;
		payloadVehicle["transport_carrier_permit"] = image_locations.filter(
			(i) => i.key === "transport_carrier_permit"
		)?.[0]?.user_picture;
		payloadVehicle["drivers_picture_2"] = image_locations.filter(
			(i) => i.key === "drivers_picture_2"
		)?.[0]?.user_picture;
		payloadVehicle["drivers_license_2"] = image_locations.filter(
			(i) => i.key === "drivers_license_2"
		)?.[0]?.user_picture;
		payloadVehicle["office_contact_number"] =
			payloadVehicle["office_contact_number"].toString();
		payloadVehicle["contact_person_number"] =
			payloadVehicle["contact_person_number"].toString();

		console.log('paylodVehicle',payloadVehicle)
		const res = await addVehicle({
			variables: {
				object: [
					{
						...payloadVehicle,
					},
				],
			},
		});
		const id = res?.data?.insert_vehicles?.returning?.[0]?.id;
		if (id) {
			const truckboysPayload = [];
			data.items.map((tb, key) => {
				truckboysPayload.push({
					name: tb.name,
					picture: tb_locations?.[key]?.user_picture,
					vehicle_id: id,
				});
			});
			const insert_tb = await addTruckBoys({
				variables: {
					object: [...truckboysPayload],
				},
			});
			const affected_rows =
				insert_tb?.data?.insert_truck_boys?.affected_rows;
			if (affected_rows > 0) {
				notification.success({
					message: "Registration sent.",
					description:
						"You have successfully registered and is now pending for review.",
				});
				setRegistrationId(id);
				setVisible(true);
			} else {
				notification.error({
					message: "Registration failed.",
					description:
						"Something went wrong while registering vehicle. error [02]",
				});
			}
		} else {
			notification.error({
				message: "Registration failed.",
				description:
					"Something went wrong while registering vehicle. error [01]",
			});
		}
		setLoading(false);
	};

	const handleImageUpload = (key, file) => {
		setImages([...images, { key, file }]);
	};

	const handleTruckBoyImages = (name, file) => {
		console.log(name);
		setTbImages([...tbimages, { key: name, file }]);
	};

	const handleDeleteTruckboyImage = (name) => {
		const removed = tbimages.filter((i) => i.key !== name);
		setTbImages([...removed]);
	};

	const handleReset = () => {
		setRegistrationId("");
		setVisible(false);
		form.resetFields();
	};

	const pageValues = {
		form,
		handleFinishForm,
		loading,
		handleImageUpload,
		handleTruckBoyImages,
		handleDeleteTruckboyImage,
		registrationId,
		visible,
		handleReset,
		progress,
	};

	return (
		<PageContext.Provider value={pageValues}>
			<RegistrationView />
		</PageContext.Provider>
	);
};

export default Registration;
