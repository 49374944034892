import _ from "lodash";
import axios from "axios";

export const formatMoney = (x) => {
	return x?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getResultAggregates = (result) => {
	const red2 = _.filter(result, { result: "2_REDS" }).length;
	const red3 = _.filter(result, { result: "3_REDS" }).length;
	const white2 = _.filter(result, { result: "2_WHITES" }).length;
	const white3 = _.filter(result, { result: "3_WHITES" }).length;
	const star1 = _.filter(result, { result: "ANY_STAR" }).length;
	const star2 = _.filter(result, { result: "TWO_STARS" }).length;
	return {
		red2,
		red3,
		white2,
		white3,
		star1,
		star2,
	};
};

export const getGameStatusIndex = (status) => {
	switch (status) {
		case "OPEN_BET":
		case "ROUND_START":
			return 0;
		case "ROUND_DONE":
			return 1;
		case "SHOW_RESULTS":
			return 5;
		case "GAME_STOPPED":
			return 7;
		default:
			return 8;
			break;
	}
};


export const uploadFile = async (file) => {
        console.log('file',file)
        var formdata = new FormData();
        formdata.append("file", file);

        const api = axios.create({
            baseURL: `https://admin-web-vert.vercel.app/api/`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        const response = await api.post("upload", formdata)

        return {
            data: {
                secure_url: response.data.url
            }
        }


}

/** export const uploadFile = (file) => {
	let formData = new FormData();
	formData.append("file", file);
	formData.append("api_key", "964517171624551");
	formData.append("upload_preset", "vehiclepass");
	formData.append("timestamp", (Date.now() / 1000) | 0);
	return axios
		.post(
			`https://api.cloudinary.com/v1_1/smartparkme/image/upload`,
			formData,
			{
				headers: { "X-Requested-With": "XMLHttpRequest" },
				// headers: {
				// 	"Content-Type": "multipart/form-data",
				// },
			}
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log("err", err);
		});
	// return axios.post('https://vehicle-api.wetrace.ph/upload', formData, {
	//   method: 'POST',
	//   headers: {
	//     Authorization: `Bearer ${process.env.REACT_APP_DEFAULT_JWT}`,
	//     'Content-Type': 'multipart/form-data'
	//   }
	// })
};
**/
