import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import NotesA from "./components/notes-a";
import NotesB from "./components/notes-b";
import NotesC from "./components/notes-c";
import RegistrationFormA from "./components/registration-form-a";
import { Button } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import RegistrationFormB from "./components/registration-form-b";
import RegistrationFormC from "./components/registration-form-c";

const RegistrationView = (props) => {
  const {
    isAgree,
    setIsAgree,
    handleClassificationChange,
    classification,
    category,
    setCategory,
  } = useContext(PageContext);
  return (
    <div className="flex flex-col h-screen items-center mb-56">
      <div className="flex flex-col items-center self-center w-full text-white bg-indigo-900 pb-5">
        <img
          src={require("../../assets/cebu-province-logo.jpg")}
          className="h-32 rounded-full"
        />
        <div>
          <p className="text-center text-2xl">VEHICLE PASS REGISTRATION</p>
        </div>
        <p className="text-base">Check the status of your application here. <a href="/scan" className="text-orange-400 font-bold text-2xl underline">Go to Scanner</a></p>
      </div>
      <RegistrationFormA />
      <div className="mt-56">
        <p>Copyright @Vehicle Pass 2021</p>
      </div>
    </div>
  );
};

export default RegistrationView;
