import React, { useRef, useState } from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, DatePicker, Form, Modal, Spin, Table, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import QrReader from "react-qr-reader";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import _ from "lodash";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

const ComponentToPrint = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <div
      className="text-gray-700"
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "row",
        width: 700,
        flexWrap: "wrap",
        textAlign: "left",
        alignItems: "center",
      }}
    >
      {props.selected.map((id, key) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop:
              (key % 12 === 0 || (key % 12 === 1 && key > 12)) && key !== 0
                ? 130
                : 10,
            // width: "50%",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${require("../../assets/new-pass.png")})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: 450,
              width: 700,
              // marginLeft: 50,
              objectFit: "fill",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                height: 71,
                width: 70,
                right: 203,
                top: 164,
              }}
            >
              <QRCode
                value={id?.id}
                size={120}
                logoWidth={20}
                logoHeight={20}
                logoImage={require("../../assets/cebu-province-logo.jpg")}
              />
            </div>
            <p
              style={{
                position: "relative",
                left: 24,
                top: 344,
                fontSize: 13,
              }}
            >{`CPG-LSTP ${_.padStart(id?.control_no?.toString(), 3, "00")}`}</p>
            <p
              style={{
                position: "relative",
                left: 154,
                top: 323,
                fontSize: 13,
              }}
            >{`${id?.company_name}`}</p>
            <p
              style={{
                position: "relative",
                left: 30,
                top: 368,
                fontSize: 13,
              }}
            >{`${id?.plate_no?.toUpperCase()}`}</p>
            <p
              style={{
                position: "relative",
                left: 145,
                top: 350,
                fontSize: 13,
              }}
            >{`${id?.region?.toUpperCase()}`}</p>
            <p
              style={{
                position: "relative",
                left: 252,
                top: 330,
                fontSize: 13,
              }}
            >{`${id?.province?.toUpperCase()}`}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
));

const ScanView = (props) => {
  const {
    handleError,
    handleScan,
    showScanner,
    setShowScanner,
    vehicleData,
    scanning,
    setVehicleData,
    setVisible,
    visible,
    handlePassApplication,
    form,
    handleImageUpload,
    loading
  } = useContext(PageContext);

  const [pdfVisible, setPdfVisible] = useState(false);

  const componentRef = useRef();
  const componentRefs = useRef();

  const columns = [
    {
      title: "Date from",
      key: "date_from",
      render: (data) => <p>{moment(data?.date_from).format("MMM-DD-YYYY")}</p>,
    },
    {
      title: "Date to",
      key: "date_to",
      render: (data) => <p>{moment(data?.date_to).format("MMM-DD-YYYY")}</p>,
    },
    {
      title: "Status",
      key: "status",
      render: (data) => <p>{data?.status}</p>,
    },
  ];

  const renderDetails = () => {
    if (vehicleData !== "") {
      const data = JSON.parse(vehicleData);

      return (
        <div className="mb-5">
          <p className="font-bold">
            Company name:{" "}
            <span className="font-medium text-blue-500">
              {data?.vehicle_info?.company_name}
            </span>
          </p>
          <p className="font-bold">
            Company address:{" "}
            <span className="font-medium text-blue-500">
              {data?.vehicle_info?.company_address}
            </span>
          </p>
          <p className="font-bold">
            Drivers name:{" "}
            <span className="font-medium text-blue-500">
              {data?.vehicle_info?.drivers_name}
            </span>
          </p>
          <p className="font-bold mb-5">
            Status:{" "}
            <span className="font-medium text-green-400">
              {data?.vehicle_info?.status === 'approved' ? 'REGISTERED' : data?.vehicle_info?.status}
            </span>
          </p>
          <p className="font-bold">Approved for Travel</p>
          <Table columns={columns} dataSource={data?.pass_applications} />
          {data?.vehicle_info?.status === "approved" && (
            <Button
            className="mt-5"
            type="primary"
            onClick={() => setVisible(true)}
          >
            Apply Travel Pass
          </Button>
        )}
      </div>
    );
    }
  };

  return (
    <div className="flex flex-col h-screen items-center mb-56">
      <div className="flex flex-col items-center self-center w-2/5">
        <img
          src={require("../../assets/cebu-province-logo.jpg")}
          className="h-32 rounded-lg"
        />
        <div>
          <p className="text-center text-sm lg:text-2xl">
            VEHICLE PASS QR Code Scanner
          </p>
        </div>
      </div>
      <p className="text-base mb-10">
        To apply for a new vehicle pass{" "}
        <a
          href="/registration"
          className="text-blue-400 font-bold text-base underline"
        >
          Go to Registration page
        </a>
      </p>
      <p className="mb-5 font-medium">Scan your QR Code to proceed</p>
      <p className="mb-5">
        NOTE: For iOS/iPhone users please use Safari Brower in order for the
        scanner to work.
      </p>
      <div>
        {scanning && (
          <div>
            <Spin />
            <p>Checking QR Code...</p>
          </div>
        )}
        {showScanner && !scanning && (
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: 200, height: 200 }}
          />
        )}
        {vehicleData !== null && !scanning && renderDetails()}
      </div>
      {!showScanner && !scanning && (
        <Button
          type="primary"
          onClick={() => {
            setVehicleData(null);
            setShowScanner(true);
          }}
        >
          Show Scanner
        </Button>
      )}
      {vehicleData !== null && !scanning && (
        <>
          <Button
            className="bg-green-400 border-green-400 mt-5"
            type="primary"
            onClick={() => exportComponentAsPNG(componentRefs)}
          >
            Export Pass as PNG
          </Button>
          <p className="my-3">Tilt your phone into landscape mode to view the whole image.</p>
          <ComponentToPrint
            ref={componentRefs}
            selected={[JSON.parse(vehicleData)?.vehicle_info]}
          />
        </>
      )}
      <div className="mt-56">
        <p>Copyright @Vehicle Pass 2021</p>
      </div>

      <Modal
        title={"Apply for pass"}
        visible={visible}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setVisible(false)}
      >
        <div>
          <p>Input Date Ranges</p>
          <Form form={form} onFinish={handlePassApplication}>
            <Form.Item
              name="date_range"
              rules={[{ required: true, message: "Must selecte date range" }]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
            <Upload
              className="self-start mb-2"
              beforeUpload={(file) =>
                handleImageUpload("notice_of_compliance", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Notice of Compliance
              </Button>
            </Upload>
            <Upload
              className="self-start mb-2"
              beforeUpload={(file) =>
                handleImageUpload("certificate_of_downtime", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Certificate of downtime
              </Button>
            </Upload>
            <Button type="primary" htmlType="submit" className="mt-5" disabled={loading}>
              {loading ? <><Spin/>Submitting..</> : 'Submit application'}
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ScanView;
